<template>
  <div class="ranking-table">
    <Flicking
        @changed="handleScroll"
        :options="{
            circular: false,
            align: 'prev',
            horizontal: true,
            moveType: 'strict',
            bound: false,
            threshold: 0,
            duration: 400,
            useFractionalSize: true,
    }">
      <div v-for="(group, groupIndex) in filteredTarotBoxes" :key="groupIndex" class="panel">
        <RankingSingleItem
            v-for="(item, index) in group"
            :key="item.id"
            :selectedCategory="selectedCategory"
            :item="item"
            :itemIndex="groupIndex * 3 + index + 1"/>
      </div>
    </Flicking>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import RankingSingleItem from "@/tarot_box/pages/home/components/main_ranking/RankingSingleItem.vue"
import Flicking from '@egjs/vue3-flicking';
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: "RankingItems",
  components: {
    RankingSingleItem,
    Flicking
  },
  data() {
    return {
    };
  },
  props: {
    selectedCategory: String
  },
  computed: {
    ...mapGetters(['tarotBoxes']),
    filteredTarotBoxes() {
      // 필터링 및 정렬
      let sortedBoxes = this.selectedCategory === '종합'
          ? this.tarotBoxes.slice()
          : this.tarotBoxes.filter(item => {
            const subjects = item.subjectTitle.split(',').map(s => s.trim());

            return subjects.includes(this.selectedCategory);
          });
      sortedBoxes.sort((a, b) => b.rankingScore - a.rankingScore);
      sortedBoxes = sortedBoxes.slice(0, 9);

      // 3개씩 그룹화
      const groupedBoxes = [];
      for (let i = 0; i < sortedBoxes.length; i += 3) {
        groupedBoxes.push(sortedBoxes.slice(i, i + 3));
      }

      return groupedBoxes;
    },
  },
  methods: {
    handleScroll(event) {
      const currentIndex = event.index;
      if (currentIndex === 0 && event.direction === 'PREV') {
        return;
      }
      logEvent('home_rank_list_swipe', {
        category_name: this.selectedCategory,
      });
    }
  }
}

</script>

<style scoped>
.ranking-table{
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  scroll-behavior: smooth;
  margin-top: 20px;
}
</style>