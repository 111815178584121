export const pushWithParams = (router, targetUrl, preserveAllParams = false) => {
    // URL과 쿼리 파라미터 분리
    const [path, search] = targetUrl.split('?');
    const targetQuery = new URLSearchParams(search || '');
    const currentQuery = new URLSearchParams(window.location.search);
    const newQuery = {};

    // 타겟 URL의 쿼리 파라미터 먼저 적용
    for (const [key, value] of targetQuery) {
        newQuery[key] = value;
    }

    // 기본 유지할 파라미터 목록
    const preservedParams = ['tarot_user_id', 'phone_number', 'os_type', 'build_number', 'inset_top', 'inset_bottom', 'is_web', 'utm_content'];

    if (preserveAllParams) {
        // 현재 URL의 파라미터 추가 (타겟 URL의 파라미터가 우선)
        for (const [key, value] of currentQuery) {
            if (!newQuery[key]) {  // 타겟 URL의 파라미터와 충돌하지 않는 경우만 추가
                newQuery[key] = value;
            }
        }
    } else {
        // 지정된 파라미터만 유지
        preservedParams.forEach(param => {
            if (currentQuery.has(param) && !newQuery[param]) {
                newQuery[param] = currentQuery.get(param);
            }
        });
    }

    router.push({
        path,
        query: newQuery
    });
};