<template>
  <div class="search-bar" @click="handleClick">
    <input
        v-model="search"
        :readonly="!isEditable"
        class="search-input"
        type="text"
        @focus="logClickEvent"
        @keyup.enter="handleSearch"
        maxlength="16"
        ref="searchInput"
    />
    <div class="placeholder-animation" v-if="!search">
      <transition
          name="placeholder-transition"
          mode="out-in"
      >
        <span :key="currentPlaceholderIndex" class="placeholder-text">
          {{ currentPlaceholder }}
        </span>
      </transition>
    </div>
    <img v-if="search && isEditable" @click.stop="clearSearch" src="@/tarot_box/pages/search/assets/erase-button.svg" alt="Clear" />
    <img v-else src="@/tarot_box/pages/search/assets/search-icon.svg" alt="Search" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {pushWithParams} from "@/utils/pushWithParams";
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: 'MainSearchBar',
  props: {
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      placeholders: [
        `'짝사랑'을 검색해보세요`,
        `'연애'를 검색해보세요`,
        `'운명'을 검색해보세요`,
        `'행운'을 검색해보세요`,
        `'미래'을 검색해보세요`,
        `'사랑'을 검색해보세요`,
        `'우정'을 검색해보세요`,
      ],
      currentPlaceholderIndex: 0,
      placeholderInterval: null
    };
  },
  computed: {
    ...mapGetters(['searchBarContents']),
    currentPlaceholder() {
      // store에서 placeholder가 있으면 사용, 없으면 기본 placeholders 배열 사용
      const storePlaceholders = this.searchBarContents?.placeholder || [];
      const useStorePlaceholders = storePlaceholders.length > 0;

      const activePlaceholders = useStorePlaceholders ? storePlaceholders : this.placeholder;
      return activePlaceholders[this.currentPlaceholderIndex % activePlaceholders.length];
    }
  },
  mounted() {
    // 기존 코드 유지
    this.startPlaceholderAnimation();

    // 검색어 업데이트 이벤트 리스너 추가
    window.addEventListener('update-search-input', this.handleUpdateSearch);

    // URL에서 검색어 파라미터 확인
    if (this.$route.query.q) {
      this.search = decodeURIComponent(this.$route.query.q);
    }
  },
  beforeUnmount() {
    // 컴포넌트가 언마운트되기 전에 인터벌 정리
    this.stopPlaceholderAnimation();
    window.removeEventListener('update-search-input', this.handleUpdateSearch);
  },
  methods: {
    startPlaceholderAnimation() {
      this.placeholderInterval = setInterval(() => {
        const storePlaceholders = this.searchBarContents?.placeholder || [];
        const useStorePlaceholders = storePlaceholders.length > 0;
        const activePlaceholders = useStorePlaceholders ? storePlaceholders : this.placeholders;

        this.currentPlaceholderIndex = (this.currentPlaceholderIndex + 1) % activePlaceholders.length;
      }, 3000); // 3초마다 placeholder 변경
    },
    stopPlaceholderAnimation() {
      if (this.placeholderInterval) {
        clearInterval(this.placeholderInterval);
        this.placeholderInterval = null;
      }
    },
    handleClick() {
      if (!this.isEditable) {
        pushWithParams(this.$router, '/tarot_box/home/search')
      } else {
        // 입력 필드에 포커스
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
      }
    },
    logClickEvent() {
      if(!this.isEditable) {
        return;
      }
      logEvent('search_field_click',{})
    },
    handleUpdateSearch(event) {
      if (event && event.detail) {
        console.log('update-search-input event received:', event.detail);
        this.search = event.detail;
      }
    },
    handleSearch() {
      if (this.search.trim()) {
        // Vue 3에서는 $root.$emit 대신 window 커스텀 이벤트 사용
        const event = new CustomEvent('search-query-updated', {
          detail: this.search
        });
        window.dispatchEvent(event);
      }
    },
    clearSearch() {
      logEvent('search_field_x_click', {});
      this.search = '';
      this.$refs.searchInput.focus();

      // 검색 페이지에서는 URL 파라미터도 제거
      if (this.$route.name === 'Search') {
        const query = {...this.$route.query};
        delete query.q;
        this.$router.replace({ query });

        // 검색 결과 초기화 이벤트 발생
        const event = new CustomEvent('search-query-updated', {
          detail: ''
        });
        window.dispatchEvent(event);
      }
    }
  },
  watch: {
    // URL의 q 파라미터가 변경될 때마다 검색어 업데이트
    '$route.query.q': {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.search = decodeURIComponent(newValue);
        } else {
          // q 파라미터가 없으면 검색어 초기화
          this.search = '';
        }
      }
    }
  },
};
</script>

<style scoped>
.search-bar{
  width: calc(100% - 32px);
  margin: 16px auto 0;
  padding: 6px 16px;
  box-sizing: border-box;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--Grayscale-Gray11);
  position: relative;
  cursor: pointer;
}

.search-input{
  width: calc(90%);
  height: 28px;
  border: none;
  background-color: transparent;
  color: var(--Grayscale-Gray3);
  font-size: 16px;
  outline: none;
}

.placeholder-animation {
  position: absolute;
  left: 16px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
  color: var(--Grayscale-Gray6, #777);
  font-size: 16px;
}

.placeholder-text {
  display: inline-block;
  width: 100%;
}

/* 롤링 트랜지션 효과 */
.placeholder-transition-enter-active,
.placeholder-transition-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.placeholder-transition-enter-from {
  opacity: 0;
  transform: translateY(-20px);
}

.placeholder-transition-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.clear-button {
  cursor: pointer;
  z-index: 2;
}
</style>