<template>
  <div class="search-page">
    <div class="search-header">
      <div class="header-upper">
        <div class="back-button" @click="goBack">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 19L8 12L15 5" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <MainSearchBar :is-editable="true" />
      </div>
    </div>

    <div v-if="!isSearching" class="search-content">
      <div class="header-lower" v-if="!isSearching">
        <div class="recent-searches-section" v-if="!isSearching && recentSearches.length > 0">
          <div class="recent-searches-header">
            <span class="recent-searches-title">최근 검색어</span>
            <span class="clear-all-button" @click="clearAllRecentSearches">모두 지우기</span>
          </div>
          <div class="recent-searches-chips">
            <div
                v-for="(search, index) in recentSearches"
                :key="`recent-${index}`"
                class="search-chip"
            >
              <span class="search-chip-text" @click="handleRecentSearchClick(search)">{{ search }}</span>
              <img
                  src="@/tarot_box/pages/search/assets/x-button.svg"
                  alt="삭제"
                  class="search-chip-delete"
                  @click="removeRecentSearch(search)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 많이 찾는 검색어 섹션 -->
      <div class="ranking-section">
        <div class="ranking-title">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.66663 10.8333H6.66663V17.5H1.66663V10.8333ZM7.49996 2.5H12.5V17.5H7.49996V2.5ZM13.3333 6.66667H18.3333V17.5H13.3333V6.66667Z" fill="#2E7FE9"/>
          </svg>
          <span>많이 찾는 검색어</span>
        </div>

        <div class="keyword-grid">
          <div
              v-for="(keyword, index) in keywords"
              :key="`keyword-${index}`"
              class="keyword-item"
              @click="handleKeywordClick(keyword)"
          >
            <div class="keyword-number">{{ index + 1 }}</div>
            <div class="keyword-text">{{ keyword }}</div>
          </div>
        </div>
      </div>

      <!-- 실시간 인기 운세 섹션 -->
      <div class="ranking-section" style="margin-top: 36px;">
        <div class="ranking-title">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5 19C6.91015 19 4 16.149 4 12.6321C4 10.8034 4.78681 9.15476 6.04688 7.99334C7.21016 6.92111 9.37333 5.24487 8.94 1C14.14 4.39623 16.22 7.11321 14.66 11.6981C15.5267 11.6981 15.7 11.1887 16.5667 10.5346C16.8004 11.1914 17 11.8972 17 12.6321C17 16.149 14.0898 19 10.5 19Z" fill="#2E7FE9"/>
          </svg>
          <span>실시간 인기 운세</span>
        </div>

        <div class="content-list">
          <div
              v-for="(boxId, index) in contentRanking"
              :key="`content-${index}`"
              class="content-item"
              @click="navigateToTarotBox(boxId)"
          >
            <div class="content-number">{{ index + 1 }}</div>
            <div class="content-text">
              <span>{{ getTarotBoxTitle(boxId) }}</span>
            </div>
            <div v-if="index === 0 && getTarotBoxThumbnail(boxId)" class="content-image">
              <img :src="getTarotBoxThumbnail(boxId)" :alt="getTarotBoxTitle(boxId)" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="search-results">
      <!-- 로딩 상태 표시 -->
      <div v-if="isLoading" class="loading">
        <div class="loading-spinner"></div>
        <p>검색 중입니다</p>
      </div>

      <!-- 검색 결과가 표시될 영역 -->
      <div v-else-if="searchResults.length === 0" class="no-results">
        <img src="@/tarot_box/pages/search/assets/no-result.svg" alt="검색 결과가 없어요" />
        <button class="no-result-button" @click="handleRetrySearch">다른 키워드로 다시 찾기</button>
      </div>
      <div v-else class="search-results-list">
        <div
            v-for="(result, index) in searchResults"
            :key="`result-${index}`"
            class="search-result-item"
            @click="navigateToTarotBox(result.id)"
        >
          <div class="result-thumbnail" v-if="result.thumbnail_v2_img_url">
            <img :src="result.thumbnail_v2_img_url" :alt="result.title" />
          </div>
          <div class="result-info">
            <div class="result-ranks">
              <div class="rank review" v-if="result.review_count && result.review_count > 0">
                <img src="@/tarot_box/pages/search/assets/review-icon.svg" alt="review" />
                리뷰 {{ result.review_count }}개
              </div>
              <div class="rank review no-review" v-else>
                <img src="@/tarot_box/pages/search/assets/review-icon.svg" alt="review" />
                첫 리뷰를 남겨보세요!
              </div>
              <div class="rank category" v-if="result.ranking_category">
                <img src="@/tarot_box/pages/search/assets/rank-icon.svg" alt="rank" />
                {{ result.ranking_category }}
              </div>
            </div>
            <div class="result-title">{{ result.title }}</div>
            <div class="result-tags">
              <span v-if="result.category" class="result-tag">
                {{ result.category }}
              </span>
              <span
                  v-for="(tag, tagIndex) in result.subject_title"
                  :key="tagIndex"
                  class="result-tag"
              >
                {{ tag }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MainSearchBar from '@/tarot_box/pages/home/components/MainSearchBar.vue';
import {searchTarotBoxes} from "@/tarot_box/helper/mytarot_api";
import {pushWithParams} from "@/utils/pushWithParams";
import {hideBottomBar, logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: 'SearchPage',
  components: {
    MainSearchBar
  },
  data() {
    return {
      isSearching: false,
      isLoading: false,
      searchQuery: '',
      searchResults: []
    };
  },
  computed: {
    ...mapGetters(['searchBarContents', 'tarotBoxes', 'recentSearches']),
    keywords() {
      return this.searchBarContents?.keyword_ranking || [];
    },
    contentRanking() {
      return this.searchBarContents?.content_ranking || [];
    }
  },
  methods: {
    onBackButtonClick() {
      this.goBack();
    },
    goBack() {
      if(this.isSearching) {
        logEvent('search_result_back', {
          keyword: this.searchQuery
        });
        this.isSearching = false;
        this.searchResults = [];

        // URL에서 q 파라미터 제거
        const query = {...this.$route.query};
        delete query.q;
        this.$router.replace({ query });

        // MainSearchBar 컴포넌트의 검색어도 초기화
        const event = new CustomEvent('update-search-input', {
          detail: ''
        });
        window.dispatchEvent(event);
      } else {
        logEvent('search_back_click',{});
        pushWithParams(this.$router, '/tarot_box/home');
      }
    },
    getTarotBoxTitle(id) {
      const tarotBox = this.tarotBoxes.find(box => box.id === id);
      return tarotBox ? tarotBox.title : '제목 없음';
    },
    getTarotBoxThumbnail(id) {
      const tarotBox = this.tarotBoxes.find(box => box.id === id);
      return tarotBox ? tarotBox.thumbnailImgUrl : null;
    },
    navigateToTarotBox(id) {
      if(this.isSearching) {
        // 검색 결과에서 클릭한 경우
        const result = this.searchResults.find(item => item.id === id);
        const index = this.searchResults.findIndex(item => item.id === id);

        if(result) {
          logEvent('search_result_click', {
            keyword: this.searchQuery,
            content_id: id,
            content_title: result.title,
            index: index + 1
          });
        }
      } else {
        // 인기 운세 섹션에서 클릭한 경우 (위 로직 활용)
        const index = this.contentRanking.indexOf(id);
        if(index !== -1) {
          const title = this.getTarotBoxTitle(id);
          logEvent('search_best_content_click', {
            content_id: id,
            content_title: title,
            rank: index + 1
          });
        }
      }

      pushWithParams(this.$router, `/tarot_box/home?tarot_box_id=${id}&router_back=true`);
    },
    handleKeywordClick(keyword) {
      logEvent('search_best_keyword_click', {
        keyword: keyword,
        rank: this.keywords.indexOf(keyword) + 1
      });
      this.searchQuery = keyword;

      // MainSearchBar 컴포넌트에 검색어 업데이트 알림
      const event = new CustomEvent('update-search-input', {
        detail: keyword
      });
      window.dispatchEvent(event);

      this.performSearch();
    },
    async performSearch() {
      if (!this.searchQuery.trim()) {
        this.isSearching = false;
        this.searchResults = [];

        // URL에서 q 파라미터 제거
        const query = {...this.$route.query};
        delete query.q;
        this.$router.replace({ query });

        return;
      }

      this.isSearching = true;

      // 검색어 저장
      this.$store.dispatch('addRecentSearch', this.searchQuery.trim());

      // URL 파라미터 업데이트 (인코딩 적용)
      this.$router.replace({
        query: {
          ...this.$route.query,
          q: encodeURIComponent(this.searchQuery.trim())
        }
      });

      // 캐시된 결과가 있는지 확인
      const cachedResults = this.$store.getters.cachedSearchResult(this.searchQuery.trim());

      if (cachedResults) {
        // 캐시된 결과가 있으면 바로 사용
        this.searchResults = cachedResults;
        this.isLoading = false;
        return;
      }

      this.isLoading = true;

      try {
        const newURL = new URLSearchParams(window.location.search);
        const isWeb = newURL.get('is_web') === 'true' || false;
        const phoneNumber = newURL.get('phone_number');
        const tarotUserId = newURL.get('tarot_user_id');

        const response = await searchTarotBoxes(this.searchQuery, isWeb, phoneNumber, tarotUserId);

        if (response.result === 'success') {
          this.searchResults = Array.isArray(response.data) ? response.data : [];

          // review_count 기준으로 정렬 (많은 순)
          this.searchResults.sort((a, b) => {
            // 리뷰 카운트가 없는 경우 0으로 처리
            const reviewA = a.review_count || 0;
            const reviewB = b.review_count || 0;
            return reviewB - reviewA; // 내림차순 정렬
          });
          // 인기 키워드 검색 여부 확인
          const isPopularKeyword = this.keywords.includes(this.searchQuery);

          logEvent('search_result_into', {
            result_count: this.searchResults.length,
            featured: isPopularKeyword,
            keyword: this.searchQuery
          });

          // 검색 결과 캐싱
          this.$store.dispatch('cacheSearchResults', {
            query: this.searchQuery.trim(),
            results: this.searchResults
          });
        } else {
          console.error('검색 API 오류:', response.message);
          this.searchResults = [];
        }
      } catch (error) {
        console.error('검색 중 오류 발생:', error);
        this.searchResults = [];
      } finally {
        this.isLoading = false;
      }
    },
    handleSearchEvent(event) {
      if (event && event.detail) {
        this.searchQuery = event.detail;
        this.performSearch();
      }
    },
    handleRecentSearchClick(search) {
      logEvent('search_recent_click', {
        keyword: search
      });
      this.searchQuery = search;

      // MainSearchBar 컴포넌트에 검색어 업데이트 알림
      const event = new CustomEvent('update-search-input,{}', {
        detail: search
      });
      window.dispatchEvent(event);

      this.performSearch();
    },
    handleRetrySearch() {
      logEvent('search_result_retry', {
        keyword: this.searchQuery
      });

      this.goBack(); // 기존 goBack 메소드 호출
    },
    removeRecentSearch(search) {
      logEvent('search_recent_del_click', {
        keyword: search
      });
      this.$store.dispatch('removeRecentSearch', search);
    },
    clearAllRecentSearches() {
      logEvent('search_recent_clear',{});
      this.$store.dispatch('clearRecentSearches');
    }
  },
  created() {
    window.onBackButtonClick = this.onBackButtonClick;
    hideBottomBar();
    logEvent('search_into', {});
    // URL에서 검색어 파라미터 확인
    if (this.$route.query.q) {
      this.searchQuery = decodeURIComponent(this.$route.query.q);

      // MainSearchBar 컴포넌트에 검색어 업데이트 알림
      const event = new CustomEvent('update-search-input', {
        detail: this.searchQuery
      });
      window.dispatchEvent(event);

      this.performSearch();
    }

    // 이벤트 리스너 등록
    window.addEventListener('search-query-updated', this.handleSearchEvent);
  },
  beforeUnmount() {
    // 이벤트 리스너 정리
    window.removeEventListener('search-query-updated', this.handleSearchEvent);

    if (window.onBackButtonClick === this.onBackButtonClick) {
      window.onBackButtonClick = null;
    }
  }
}
</script>

<style scoped>
.search-page {
  background-color: #ffffff;
  min-height: calc(100dvh - 10px);
}

.search-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--safe-area-top) 20px 16px;
  box-sizing: border-box;
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
}

.header-upper,
.header-lower {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.header-upper {
  align-items: center;
  justify-content: space-between;
}

.back-button {
  margin-top: 16px;
  margin-right: 8px;
  cursor: pointer;
}

.recent-searches-section {
  width: 100%;
  padding-top: 20px;
}

.recent-searches-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 0 16px;
}

.recent-searches-title {
  font-size: 14px;
  font-weight: 700;
  color: var(--Grayscale-Gray5);
}

.clear-all-button {
  font-size: 12px;
  font-weight: 400;
  color: var(--Grayscale-Gray6);
  cursor: pointer;
}

.recent-searches-chips {
  display: flex;
  gap: 6px;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 12px;
  padding-left: 16px;
}

.search-chip {
  display: inline-flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 50px;
  border: 1px solid var(--Grayscale-Gray10);
  background-color: #ffffff;
}

.search-chip:last-child {
  margin-right: 16px;
}

.search-chip-text {
  font-size: 14px;
  color: var(--Grayscale-Gray4);
  margin-right: 4px;
  cursor: pointer;
}

.search-chip-delete {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.search-content{
  box-sizing: border-box;
  padding-top: calc(var(--safe-area-top) + 60px);
  padding-bottom: calc(var(--safe-area-bottom) + 20px);
}

.ranking-section {
  margin-top: 16px;
  padding: 0 16px;
}

.ranking-title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;
}

.ranking-title svg {
  margin-right: 8px;
}

.keyword-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2개의 열 생성 */
  grid-template-rows: repeat(3, auto);   /* 3개의 행 생성 */
  grid-auto-flow: column;                /* 열 방향으로 아이템 배치 */
  gap: 8px;
}

.keyword-item {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  background-color: #EFF8FF;
  border-radius: 50px;
  cursor: pointer;
}

.keyword-item:nth-child(4),
.keyword-item:nth-child(5),
.keyword-item:nth-child(6) {
  background-color: transparent;
}

.keyword-item:nth-child(1) > .keyword-text,
.keyword-item:nth-child(2) > .keyword-text,
.keyword-item:nth-child(3) > .keyword-text {
  color: #00497A;
}

.keyword-number {
  font-family: "Afacad", sans-serif;
  font-weight: bold;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3D72D3;
  font-size: 18px;
  margin-right: 10px;
}

.keyword-text {
  font-size: 14px;
  font-weight: 500;
}

.content-list {
  display: flex;
  flex-direction: column;
}

.content-item {
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  padding: 20px;
  cursor: pointer;
}

.content-item:first-child {
  background: #EFF8FF;
  border-radius: 12px;
}

.content-item:first-child > .content-number {
  color: #2E7BEE;
}

.content-item:first-child > .content-text {
  color: #00497A;
}

.content-number {
  font-family: "Afacad", sans-serif;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Grayscale-Gray4);
  font-weight: bold;
  margin-right: 16px;
  font-size: 20px;
}

.content-text {
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-image {
  position: absolute;
  right: 20px;
  bottom: 12px;
  width: 57px;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;
  margin-left: 12px;
}

.content-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.no-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - var(--safe-area-top) - var(--safe-area-bottom) - 200px);
  padding-top: calc(var(--safe-area-top) + 80px);
  padding-bottom: calc(var(--safe-area-bottom) + 20px);
  gap: 28px;
}

.no-result-button{
  padding: 10px 16px;
  border-radius: 50px;
  border: 1px solid var(--Grayscale-Gray10);
  color: var(--Grayscale-Gray4);
  font-size: 14px;
}

.search-results {
  box-sizing: border-box;
  padding-top: calc(var(--safe-area-top) + 80px);
  padding-bottom: calc(var(--safe-area-bottom) + 20px);
}

.search-results-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px;
  box-sizing: border-box;
}

.search-result-item {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
}

.result-thumbnail {
  width: 72px;
  height: 100px;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
}

.result-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.result-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
}

.result-ranks {
  display: flex;
  gap: 4px;
}

.rank {
  font-size: 12px;
  font-weight: 700;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
}

.rank img{
  margin-right: 4px;
}

.rank.review {
  background-color: #EFF8FF;
  color: #00497A;
}

.rank.review.no-review {
  background-color: #EFF8FF;
  color: #76AACC;
}

.rank.category {
  background-color: #FFF3EC;
  color: #E05C15;
}

.result-title {
  font-size: 14px;
  text-align: left;
  font-weight: 500;
  margin-bottom: 4px;
  word-break: keep-all;
  line-height: 1.4;
}

.result-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.result-tag {
  font-size: 10px;
  font-weight: 700;
  padding: 3px 10px;
  border-radius: 2px;
  display: inline-block;
  color: var(--Grayscale-Gray5, #777);
  background: var(--Grayscale-Gray10, #ECECEC);
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - var(--safe-area-top) - var(--safe-area-bottom) - 200px);
  color: #888;
}

.loading-spinner {
  width: 30px;
  height: 30px;
  border: 3px solid rgba(46, 123, 238, 0.2);
  border-top: 3px solid #2E7BEE;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 12px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>