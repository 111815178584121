import axios from 'axios';
import * as Sentry from "@sentry/vue";
import md5 from 'crypto-js/md5';

console.log('VUE_APP_API_HOST:', process.env.VUE_APP_API_HOST);

// export const HOST = process.env.VUE_APP_API_HOST || "https://development-dot-meta-play-project.du.r.appspot.com";
export const HOST = process.env.VUE_APP_API_HOST || "https://api.mytarot.kr";
export const DEBUG = process.env.VUE_APP_DEBUG === 'true';

console.log('Current NODE_ENV:', process.env.NODE_ENV);
console.log('Current API Host:', HOST);
console.log('Debug Mode:', DEBUG);

export const api = axios.create({ baseURL: HOST });

function getCommonParams() {
    const newURL = new URLSearchParams(window.location.search);
    const isWeb = newURL.get('is_web') === 'true';
    const isShare = newURL.get('is_share') === 'true';

    if (isWeb || isShare) {
        return {}; // 빈 객체 반환
    }
    const rawOsType = newURL.get('os_type');
    let osType;
    if (rawOsType === 'aos') {
        osType = 'android';
    } else if (rawOsType === 'ios') {
        osType = 'ios';
    } else {
        osType = 'android'; // 기본값
    }
    const buildNumber = newURL.get('build_number');
    const timestamp = Date.now();
    const sig = generateSig(timestamp, osType);

    return {
        target_os: osType,
        app_ver: buildNumber,
        language: 'ko',
        ts: timestamp,
        service: 'mytarot',
        sig: sig
    };
}

function generateSig(ts, targetOs) {
    const dataToHash = `${ts}${targetOs}playtown221218`;
    const md5Digest = md5(dataToHash);
    return md5Digest.toString();

}

export async function getTarotBoxInputDataByFetch(tarotBoxId) {
    let formTarotBoxData={"tarot_box_id": tarotBoxId}
    const response = await fetch(HOST+'/my_tarot/input_meta_data', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formTarotBoxData)
    })
    if (!response.ok) {
        console.error('API 호출에 실패했습니다.');
        return null;
    }
    const jsonData = await response.json();
    if(jsonData.result !== "success"){
        console.log(jsonData.message)
    }
    return JSON.parse(jsonData.data);
}
export async function getFaceDataByFetch(imageUrl) {
    const formData = {img_url: imageUrl};
    const response = await fetch(HOST+'/my_tarot/get_face_data', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    })
    if (!response.ok) {
        Sentry.captureMessage(`Old! Get Face Data API Failed: ${window.location.href}`);
    }
    return await response.json();
}

export async function uploadImageFile(imageFile) {
    const formData = {img_file: imageFile};
    let res = await axios.post(HOST+'/my_tarot/boxes/upload_image', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    return res.data;
}

export async function getMyTarotResultByFetch(hashId) {
    let formData={"hash_id": hashId}
    const response = await fetch(HOST+'/my_tarot/result_hash', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    })
    const jsonData = await response.json();

    if(jsonData.result !== "success"){
        console.log(jsonData.message)
        console.log(jsonData.data)
    }
    return jsonData.data;
}

export async function getMySajuByFetch(userId) {
    let formData={"tarot_user_id": userId}
    const response = await fetch(HOST+'/my_tarot/get_my_saju_date', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    })
    const jsonData = await response.json();
    if(jsonData.result !== "success"){
        console.log(jsonData.message)
    }
    return jsonData.data;
}

export async function getMyManseDataByFetch(hashId) {
    let formData = {"hash_id": hashId}
    const response = await fetch(HOST + '/my_tarot/get_manse_data_by_hash_id', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    })

    const jsonData = await response.json();
    if (jsonData.result !== "success") {
        console.log(jsonData.message)
    }
    return jsonData.data;
}

export async function makeBoxResult(userId, phoneNumber, userInputData, pickedCardIds, tarotBoxId) {
    const response = await defaultApiCall('/my_tarot/make_box_result', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        user_input_params: userInputData,
        picked_card_ids: pickedCardIds,
        tarot_box_id: tarotBoxId
    });
    return response;
}

export async function purchaseBox(userId, phoneNumber, tarotBoxHistoryId) {
    const response = await defaultApiCall('/my_tarot/purchase_box', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        tarot_box_history_id: tarotBoxHistoryId,
    });
    return response;
}

export async function getTarotUserInfo(userId, phoneNumber) {
    const response = await defaultApiCall('/my_tarot/tarot_user_info', {
        phone_number: phoneNumber,
        tarot_user_id: userId
    });
    return response;
}

export async function getUserCloverHistory(userId, phoneNumber) {
    const response = await defaultApiCall('/my_tarot/user_history', {
        phone_number: phoneNumber,
        tarot_user_id: userId
    });
    return response;
}

//2024.02.19 업데이트 by rob
//실제 유저 정보 없이도 타로박스 리스트를 불러오는 함수
export async function getTarotBoxItemsByFetch(phoneNumber, userId, isWeb) {
    const response = await defaultApiCall('/my_tarot/home/box_data_v2', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        is_web: isWeb
    });
    console.log()
    return response;
}

export async function getFullTarotBoxItems(phoneNumber, userId, isWeb) {
    const response = await defaultApiCall('/my_tarot/home/box_data', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        is_web: isWeb
    });
    return response;
}

export async function getRecommendBoxIds(phoneNumber, userId) {
    const response = await defaultApiCall('/my_tarot/home/recommend_box_ids', {
        phone_number: phoneNumber,
        tarot_user_id: userId
    });
    return response;
}

export async function recordTarotBoxClick(phoneNumber, userId, tarotBoxId) {
    const response = await defaultApiCall('/my_tarot/home/record_box_click', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        tarot_box_id: tarotBoxId
    });
    return response;
}

export async function askExtraQuestion(tarotBoxHistoryId, extraQuestion) {
    const response = await defaultApiCall('/my_tarot/ask_extra_question', {
        tarot_box_history_id: tarotBoxHistoryId,
        extra_question: extraQuestion
    });
    return response;
}

export async function markReadExtraAnswer(tarotBoxHistoryId) {
    const response = await defaultApiCall('/my_tarot/read_extra_question', {
        tarot_box_history_id: tarotBoxHistoryId
    });
    return response;
}

//입력페이지v2에서 사주명식을 내리는 함수

export async function getSajuCharacters(date, sajutime){
    const response = await defaultApiCall('/my_tarot/saju_characters', {
        date: date,
        sajutime: sajutime
    });
    return response;
}


//타로박스 리스트 불러오는 함수
export async function getTarotBoxItems(phoneNumber, userId) {
    const response = await defaultApiCall('/my_tarot/available_boxes', {
        phone_number: phoneNumber,
        tarot_user_id: userId
    });
    return response;
}

//2024.01.11 업데이트 (나의 타로박스 히스토리, 타로박스 삭제, 리뷰 리스트, 리뷰 전송)
export async function getUserBoxHistory(phoneNumber, userId) {
    const response = await defaultApiCall('/my_tarot/user_box_history', {
        phone_number: phoneNumber,
        tarot_user_id: userId
    });
    return response;
}

export async function deleteBoxHistory(phoneNumber, userId, tarotBoxHistoryId) {
    const response = await defaultApiCall('/my_tarot/remove_box', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        tarot_box_history_id: tarotBoxHistoryId
    });
    return response;
}

export async function getBoxReviewList(tarotBoxId){
    const response = await defaultApiCall('/my_tarot/review_list', {
        tarot_box_id: tarotBoxId
    });
    return response;
}

export async function submitBoxReview(phoneNumber, userId, tarotBoxId, tarotBoxHistoryId, userReaction, userReview){
    const response = await defaultApiCall('/my_tarot/write_feedback', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        tarot_box_id: tarotBoxId,
        tarot_box_history_id: tarotBoxHistoryId,
        user_reaction: userReaction,
        user_review: userReview
    });
    return response;
}

export async function startSMSAuth(phoneNumber) {
    const response = await defaultApiCall('/my_tarot/start_sms_auth', {
        phone_number: phoneNumber
    });
    return response;
}

export async function finishSMSAuth(phoneNumber, certNumber, displayName, installReferrer) {
    const response = await defaultApiCall('/my_tarot/web/finish_sms_auth', {
        phone_number: phoneNumber,
        cert_number: certNumber,
        display_name: displayName,
        install_referrer: installReferrer
    });
    return response;
}

export async function finishAppSMSAuth(phoneNumber, certNumber, installReferrer){
    const response = await defaultApiCall('/my_tarot/finish_sms_auth', {
        phone_number: phoneNumber,
        cert_number: certNumber,
        install_referrer: installReferrer,
    });
    return response;
}

export async function getPayplePartnerAuth() {
    const response = await defaultApiCall('/my_tarot/web/payple_partner_auth', {});
    return response;
}

export async function changeNickname (phoneNumber, userId, nickname) {
    const response = await defaultApiCall('/my_tarot/update_display_name', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        display_name: nickname
    });
    return response;
}

export async function removeUser(phoneNumber, userId) {
    const response = await defaultApiCall('/my_tarot/remove_user', {
        phone_number: phoneNumber,
        tarot_user_id: userId
    });
    return response;
}

export async function updateReminderTime(phoneNumber, userId, reminderTime) {
    const response = await defaultApiCall('/my_tarot/update_reminder_time', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        reminder_time: reminderTime
    });
    return response;
}

export async function getInviteHistory(phoneNumber, userId) {
    const response = await defaultApiCall('/my_tarot/referral_history', {
        phone_number: phoneNumber,
        tarot_user_id: userId
    });
    return response;
}

export async function makeReferral(phoneNumber, userId, referralCode) {
    const response = await defaultApiCall('/my_tarot/make_referral', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        referral_code: referralCode
    });
    return response;
}

export async function getMyDailyFortune (phoneNumber, userId, buildNumber) {
    const response = await defaultApiCall('/my_tarot/my_daily_fortune_info', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        build_number: buildNumber
    });
    return response;
}

export async function recordLastClickedItem(phoneNumber, userId, price, cloverQuantity, productName) {
    const response = await defaultApiCall('/my_tarot/record_last_clicked_item', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        product_id: 'product_id_for_recover',
        price: price,
        clover_quantity: cloverQuantity,
        product_name: productName
    });
    return response;
}

export async function updateWebPurchaseReferrer(orderId, referrer) {
    const response = await defaultApiCall('/my_tarot/update_purchase_referrer', {
        transaction_id: orderId,
        referrer: referrer
    });
    return response;
}

export async function getExtarQuestionPaywall(tarotUserId, phoneNumber) {
    const response = await defaultApiCall('/my_tarot/extra_question_paywall', {
        tarot_user_id: tarotUserId,
        phone_number: phoneNumber
    });
    return response;
}

export async function purchaseExtraQuestionTicket(tarotUserId, phoneNumber, productId) {
    const response = await defaultApiCall('/my_tarot/purchase_extra_question', {
        tarot_user_id: tarotUserId,
        phone_number: phoneNumber,
        product_id: productId
    });
    return response;
}

export async function requestPaidExtraQuestion(tarotBoxHistoryId, extraQuestion) {
    const response = await defaultApiCall('/my_tarot/extra_question_paid', {
        tarot_box_history_id: tarotBoxHistoryId,
        extra_question: extraQuestion
    });
    return response;
}

export async function getEventList(event_type = 'coupon'){
    const response = await defaultApiCall('/my_tarot/events', {
        event_type: event_type
    });
    return response;
}

export async function sumbitCouponCode(phoneNumber, userId, couponCode) {
    const response = await defaultApiCall('/my_tarot/enter_coupon_code', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        coupon_code: couponCode
    });
    return response;
}

export async function getExtraQuestionHistory(userId, phoneNumber){
    const response = await defaultApiCall('/my_tarot/extra_question_history', {
        tarot_user_id: userId,
        phone_number: phoneNumber,
    });
    return response;
}

export async function updateAppPurchaseReferrer(userId, phoneNumber, referrer){
    const response = await defaultApiCall('/my_tarot/update_app_purchase_referrer', {
        tarot_user_id: userId,
        phone_number: phoneNumber,
        referrer: referrer
    });
    return response;
}

export async function getPopupList(){
    const response = await defaultApiCall('/my_tarot/popups', {
    });
    return response;
}

// 사주정보 APIs
export async function getBirthInfos(phoneNumber, userId) {
    const response = await defaultApiCall('/my_tarot/birth_infos', {
        phone_number: phoneNumber,
        tarot_user_id: userId
    });
    return response;
}

export async function createBirthInfo(phoneNumber, userId, birthDatetime, isUserBirthInfo, gender, name) {
    const response = await defaultApiCall('/my_tarot/create_birth_info', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        birth_datetime: birthDatetime,
        is_user_birth_info: isUserBirthInfo,
        gender: gender,
        name: name,
    });
    return response;
}

export async function updateBirthInfo(phoneNumber, userId, birthInfoId, birthDatetime, isUserBirthInfo, gender, name) {
    const response = await defaultApiCall('/my_tarot/update_birth_info', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        tarot_user_birth_info_id: birthInfoId,
        birth_datetime: birthDatetime,
        is_user_birth_info: isUserBirthInfo,
        gender: gender,
        name: name,
    });
    return response;
}

export async function deleteBirthInfo(phoneNumber, userId, birthInfoId) {
    const response = await defaultApiCall('/my_tarot/delete_birth_info', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        tarot_user_birth_info_id: birthInfoId
    });
    return response;
}

export async function getCampaignInfo(campaignCode, tarotBoxId) {
    const response = await defaultApiCall('/my_tarot/campaign', {
        campaign_code: campaignCode,
        tarot_box_id: tarotBoxId
    });
    return response;
}

export async function searchTarotBoxes(query, isWeb, phoneNumber, userId) {
    try {
        // 공통 파라미터 가져오기
        const commonParams = getCommonParams();

        // URL 파라미터 구성
        const params = new URLSearchParams({
            ...commonParams,
            query: query,
            is_web: isWeb,
            phone_number: phoneNumber || '01000000000',
            tarot_user_id: userId || 100
        });

        // GET 요청 보내기
        const response = await fetch(`${HOST}/my_tarot/search?${params.toString()}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            console.error("검색 API 호출에 실패했습니다.");
            Sentry.captureMessage(`Search API Failed: ${window.location.href}, query=${query}`);
            return { result: 'failed' };
        }

        const jsonData = await response.json();
        if (jsonData.result !== "success") {
            console.log(jsonData.message);
        }

        return jsonData;
    } catch (error) {
        console.error("검색 API 통신 중 오류 발생:", error);
        return { result: 'failed' };
    }
}

/**
* 체험 이력 확인 API
* 전화번호와 템플릿 ID를 기반으로 사용자의 체험 이력을 확인합니다.
* @param {string} templateId - AI 통화 템플릿 ID
* @param {string} phoneNumber - 확인할 전화번호 (국제 형식: +821012345678)
* @returns {Promise<Object>} - 체험 이력 확인 결과
*/
export async function checkTrialHistory(templateId, phoneNumber) {
    try {
        const response = await fetch(`${HOST}/ai_call/check_trial_history`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ai_call_template_id: templateId,
                ph: phoneNumber
            })
        });

        if (!response.ok) {
            console.error('체험 이력 확인 API 호출에 실패했습니다.');
            return { result: 'fail', message: '서버 통신 오류' };
        }

        return await response.json();
    } catch (error) {
        console.error('체험 이력 확인 API 오류:', error);
        return { result: 'fail', message: '네트워크 오류' };
    }
}

/**
 * Bland 통화 이력 조회 API
 * Bland 통화 ID를 사용하여 통화 이력 상세 정보를 조회합니다.
 * @param {string} callId - Bland 통화 ID
 * @returns {Promise<Object>} - 통화 이력 상세 정보
 */
export async function getBlandHistoryByCallId(callId) {
    try {
        const response = await fetch(`${HOST}/ai_call/bland_history_by_call_id`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                call_id: callId
            })
        });

        if (!response.ok) {
            console.error('Bland 통화 이력 조회 API 호출에 실패했습니다.');
            return { result: 'fail', message: '서버 통신 오류' };
        }

        return await response.json();
    } catch (error) {
        console.error('Bland 통화 이력 조회 API 오류:', error);
        return { result: 'fail', message: '네트워크 오류' };
    }
}

/**
 * Bland 통화 발신 API
 * 템플릿을 사용하여 새로운 AI 통화를 시작합니다.
 * @param {string} templateId - AI 통화 템플릿 ID
 * @param {string} phoneNumber - 통화할 전화번호 (국제 형식: +821012345678)
 * @returns {Promise<Object>} - 통화 시작 결과
 */
export async function startBlandCall(templateId, phoneNumber) {
    try {
        const response = await fetch(`${HOST}/ai_call/bland_send_call`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                template_id: templateId,
                ph: phoneNumber
            })
        });

        if (!response.ok) {
            console.error('Bland 통화 발신 API 호출에 실패했습니다.');
            return { result: 'fail', message: '서버 통신 오류' };
        }

        return await response.json();
    } catch (error) {
        console.error('Bland 통화 발신 API 오류:', error);
        return { result: 'fail', message: '네트워크 오류' };
    }
}

/**
 * API 응답에서 통화 결과의 상태를 주기적으로 확인합니다.
 * @param {string} callId - Bland 통화 ID
 * @param {number} intervalMs - 확인 간격 (밀리초)
 * @param {number} maxAttempts - 최대 시도 횟수
 * @param {Function} onUpdate - 상태 업데이트 시 호출될 콜백 함수
 * @param {Function} onComplete - 통화 완료 시 호출될 콜백 함수
 * @returns {Function} - 폴링을 중지하는 함수
 */
export function pollCallStatus(callId, intervalMs = 5000, maxAttempts = 36, onUpdate, onComplete) {
    let attempts = 0;
    let stopPolling = false;

    const checkStatus = async () => {
        if (stopPolling || attempts >= maxAttempts) return;

        attempts++;
        try {
            const response = await getBlandHistoryByCallId(callId);

            if (response.result === 'success') {
                const callStatus = response.data.status;

                // 상태 업데이트 콜백 호출
                if (onUpdate) {
                    onUpdate(response.data);
                }

                // 통화가 완료되었거나 종료된 상태 확인
                if (callStatus === 'completed' || callStatus === 'ended' || callStatus === 'failed') {
                    if (onComplete) {
                        onComplete(response.data);
                    }
                    return; // 폴링 중지
                }
            }

            // 계속 폴링
            if (!stopPolling && attempts < maxAttempts) {
                setTimeout(checkStatus, intervalMs);
            }
        } catch (error) {
            console.error('통화 상태 확인 중 오류:', error);
            // 오류 발생 시에도 계속 폴링
            if (!stopPolling && attempts < maxAttempts) {
                setTimeout(checkStatus, intervalMs);
            }
        }
    };

    // 최초 상태 확인 시작
    checkStatus();

    // 폴링 중지 함수 반환
    return () => {
        stopPolling = true;
    };
}



async function defaultApiCall(url, formData) {
    const MAX_RETRY = 2;
    let attempts = 0;

    while (attempts <= MAX_RETRY) {
        try {
            const commonParams = getCommonParams();
            const combinedData = { ...commonParams, ...formData };

            const response = await fetch(`${HOST}${url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(combinedData)
            });
            if (!response.ok) {
                console.log("API 호출에 실패했습니다.");
                Sentry.captureMessage(`${url} API Failed: ${window.location.href}, ${JSON.stringify(combinedData)}`);
            }

            const jsonData = await response.json();
            if (jsonData.result != "success") {
                console.log(jsonData.message);
            }
            console.log(jsonData);
            return jsonData;
        }
        catch (error) {
            attempts++;
            await sleep(500);
            console.error(`API 통신 중 오류 발생 (시도 ${attempts}):`, error);

            if (attempts > MAX_RETRY) {
                console.error("API 호출에 실패했습니다.");
                return { result: 'failed' };
            }
        }
    }
}




function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
